import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Field, Form, Formik, useFormik } from 'formik';
import { Container, Box, Typography, TextField, Button, CssBaseline, FormControl, InputLabel, Select, MenuItem, Grid, FormControlLabel, Checkbox, Tab, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { ArrowBack, Block, CheckBox } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import axiosInstance from '../../axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import uploadfile_service from '../../services/uploadfile_service';
export default function AddSubjectPointStep(){
  const defaultTheme = createTheme();
  let { id} = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState('1');
  const [tabDisabled,settabDisabled] =useState(true);
  const [IsActive, setIsActive] = useState(false);
  const [Status, setStatus] = useState(false);
  const [Deleted, setDeleted] = useState(false);
  const [selectedSubjectPointStepBaseImageSM, setselectedSubjectPointStepBaseImageSM] = useState(null);
  const [selectedSubjectPointStepImageSM, setselectedSubjectPointStepImageSM] = useState(null);
  const [selectedSubjectPointStepBaseImageLG, setselectedSubjectPointStepBaseImageLG] = useState(null);
  const [selectedSubjectPointStepImageLG, setselectedSubjectPointStepImageLG] = useState(null);
  const [ImageIDSM,setImageIDSM]=useState(0);
  const [ImageIDLG,setImageIDLG]=useState(0);
  const [CourseList,setCourseList]=useState([]);
  const [CourseID, setCourseID] = useState(0);
  const [SubjectList,setSubjectList]=useState([]);
  const [SubjectID, setSubjectID] = useState(0);
  const [SubjectPointList,setSubjectPointList]=useState([]);
  const [SubjectPointID, setSubjectPointID] = useState(0);
  async function displayImageByNameLG(
    filename
  ) {
    var date = new Date();
    axiosInstance.get('File/GetImagebase64/?ndate='+ date.toDateString()+'&filename='+ filename).then( (response)=>{
      setselectedSubjectPointStepBaseImageLG(response.data);
     });
  };
  async function displayImageByNameSM(
    filename
  ) {
    var date = new Date();
    axiosInstance.get('File/GetImagebase64/?ndate='+ date.toDateString()+'&filename='+ filename).then( (response)=>{
      setselectedSubjectPointStepBaseImageSM(response.data);
     });
  };
  useEffect((event)=>{
    axiosInstance.get('Course/GetAllCourseList').then((response)=>{
        console.log(response.data);
        setCourseList(response.data);
       });
    console.log("id");
    console.log(id);
    if(id != undefined || id != null){
        settabDisabled(false);
        axiosInstance.get('SubjectPointStep/GetSubjectPointStepMaster/' + id).then((response)=>{
            //console.log(response.data);
            //console.log(response.data.Data[0]);
            var ndata=response.data.Data[0];
            console.log('ndata');
            console.log(ndata);
            addform.values.SubjectStepName=ndata.SubjectStepName;
            addform.values.SubjectId=ndata.SubjectID;
            addform.values.SubjectStepDescription=ndata.SubjectStepDescription;
            addform.values.SubjectStepImageIdlg=ndata.SubjectStepImageIDLG;
            addform.values.SubjectStepImageIdsm=ndata.SubjectStepImageIDSM;
            addform.values.SubjectStepVideoUrl=ndata.SubjectStepVideoUrl;
            addform.values.IsActive=ndata.IsActive;
            addform.values.OrderNo=ndata.OrderNo;
            addform.values.CourseId=ndata.CourseID;
            addform.values.SubjectPointId=ndata.SubjectPointID;
            setIsActive(ndata.IsActive);
            setImageIDLG(ndata.SubjectStepImageIDLG);
            setImageIDSM(ndata.SubjectStepImageIDSM);
            setCourseID(ndata.CourseID);
            getSubjectList(ndata.CourseID);
            setSubjectID(ndata.SubjectID);
            getSubjectPointList(ndata.SubjectID);
            setSubjectPointID(ndata.SubjectPointID);
            if(ndata.ImageNameLG!=null){
                displayImageByNameLG(ndata.ImageNameLG);
            }
            if(ndata.ImageNameSM!=null){
                displayImageByNameSM(ndata.ImageNameSM);
            }
           });
    }
  },[]);

  const addform = useFormik({
    initialValues: {
        CourseId: '',
        SubjectPointId: '',
        SubjectId: '',
        SubjectStepName: '',
        SubjectStepImageIdlg: 0,
        SubjectStepImageIdsm: 0,
        SubjectStepDescription: '',
        SubjectStepVideoUrl: '',
        OrderNo: '',
        IsActive: true,
    },
    validationSchema: Yup.object({
        CourseId:Yup.string().required('Course is required'),
        SubjectPointId: Yup.string().required('Subject Point is required'),
        SubjectId: Yup.string().required('Subject is required'),
        SubjectStepName: Yup.string().required('Subject Point Step is required'),
        SubjectStepDescription: Yup.string().required('SSubject Step Description is required'),
        OrderNo: Yup.string().required('OrderNo is required'),
    }),
    onSubmit: (values) => {
        console.log(values)
        if(id===undefined || id ===null){
            axiosInstance.post('SubjectPointStep/AddSubjectPointStepMaster',{
                "SubjectStepId": 0,
                "SubjectPointId": values.SubjectPointId,
                "SubjectId": values.SubjectId,
                "SubjectStepName": values.SubjectStepName,
                "SubjectStepImageIdlg": values.SubjectStepImageIdlg,
                "SubjectStepImageIdsm": values.SubjectStepImageIdsm,
                "SubjectStepDescription": values.SubjectStepDescription,
                "SubjectStepVideoUrl": values.SubjectStepVideoUrl,
                "OrderNo": values.OrderNo,
                "IsActive": values.IsActive,
            },
            ).then((response)=>{
                //getVideoList();
                console.log(response.data);
                alert("Added SubjectPointStep successfully")
            });
        }
        else{
            axiosInstance.post('SubjectPointStep/UpdateSubjectPointStepMaster',{
                "SubjectStepId": parseInt(id),
                "SubjectPointId": values.SubjectPointId,
                "SubjectId": values.SubjectId,
                "SubjectStepName": values.SubjectStepName,
                "SubjectStepImageIdlg": values.SubjectStepImageIdlg,
                "SubjectStepImageIdsm": values.SubjectStepImageIdsm,
                "SubjectStepDescription": values.SubjectStepDescription,
                "SubjectStepVideoUrl": values.SubjectStepVideoUrl,
                "OrderNo": values.OrderNo,
                "IsActive": values.IsActive,
            },
            ).then((response)=>{
                //getVideoList();
                console.log(response.data);
                alert("Updated SubjectPointStep successfully")
                
            });
        }
       
    }
  });
  const routeChange = () =>{ 
    navigate('/SubjectPointSteplist');
  }
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  }; 
  function getSubjectList(courseid){
    axiosInstance.get('Subject/GetSubjectListByCourseID/?courseid=' + courseid).then((response)=>{
        console.log(response.data);
        setSubjectList(response.data);
       });
  }
  function getSubjectPointList(subjectid){
    axiosInstance.get('SubjectPoint/GetSubjectPointListBySubjectID/?subjectid=' + subjectid).then((response)=>{
        console.log(response.data);
        setSubjectPointList(response.data);
       });
  }
  const handleIsActiveChange = (event) => {
    setIsActive(IsActive?false:true)
    addform.values.IsActive=IsActive?false:true;
  };
  const handleCourseIDChange = (event) => {
    console.log(event.target.value);
    setCourseID(event.target.value);
    getSubjectList(event.target.value);
    addform.values.CourseId=event.target.value;
  };
  const handleSubjectIDChange = (event) => {
    console.log(event.target.value);
    setSubjectID(event.target.value);
    getSubjectPointList(event.target.value);
    addform.values.SubjectId=event.target.value;
  };
  const handleSubjectPointIDChange = (event) => {
    console.log(event.target.value);
    setSubjectPointID(event.target.value);
    addform.values.SubjectPointId=event.target.value;
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
    })
  };
  
  const UploadSubjectPointStepImageLG = event => {
    console.log('id:' + id);
    console.log('imageidlg:' + ImageIDLG);
    uploadfile_service.UploadSubjectPointStepImageType(selectedSubjectPointStepImageLG,id,ImageIDLG,2).then((data)=>{
      //alert(data.data.Message)
      alert("Record saved successfull");
      console.log(data);
    }).catch((error)=>{
      alert("Failed");
      console.log(error);
    })
  };
  const UploadSubjectPointStepImageSM = event => {
    console.log('id:' + id);
    console.log('imageidsm:' + ImageIDSM);
    uploadfile_service.UploadSubjectPointStepImageType(selectedSubjectPointStepImageSM,id,ImageIDSM,1).then((data)=>{
      //alert(data.data.Message)
      alert("Record saved successfull");
      console.log(data);
    }).catch((error)=>{
      alert("Failed");
      console.log(error);
    })
  };
  
    return(
        <ThemeProvider theme={defaultTheme}>
        <Container component="main">
          <CssBaseline />
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               <Grid item xs={6}>
                  <Button 
                    style={{width:"150px"}}
                    startIcon={<ArrowBack />} onClick={routeChange}>
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="h6" variant="h6">
                      Add Subject Point Step
                    </Typography>
                </Grid>
          </Grid>
          
          
            <div style={{textAlign:'left'}}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                    <Tab label="Subject Point Step detail" value="1" />
                    <Tab label="Image" value="2" disabled={tabDisabled}/>
                  </TabList>
                </Box>
                <TabPanel value="1">
                <Box
                    component="form"
                    // sx={{
                    //   '& .MuiTextField-root': { m: 1, width: '35ch' },
                    // }}
                    onSubmit={addform.handleSubmit}
                  >
                  <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={6} sm={6} md={6}>
                                <FormControl fullWidth>
                                        <InputLabel id="lCourseId">Course</InputLabel>
                                        <Select
                                        labelId="lCourseId"
                                        id="CourseId"
                                        value={CourseID}
                                        label="Course"
                                        onChange={handleCourseIDChange}
                                        onBlur={addform.handleBlur}
                                        error={addform.errors.CourseId && addform.touched.CourseId ?true:false}
                                        >
                                        {CourseList.map((course,index) => (
                                            <MenuItem
                                            value={course.CourseId}
                                            key={`course_courseid${index}`}
                                            >
                                            <em>{course.CourseName}</em>
                                            </MenuItem>
                                        ))}
                                        
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                <FormControl fullWidth>
                                        <InputLabel id="lSubjectId">Subject</InputLabel>
                                        <Select
                                        labelId="lSubjectId"
                                        id="SubjectId"
                                        value={SubjectID}
                                        label="Subject"
                                        onChange={handleSubjectIDChange}
                                        onBlur={addform.handleBlur}
                                        error={addform.errors.SubjectId && addform.touched.SubjectId ?true:false}
                                        //helperText={addform.errors.SubjectId && addform.touched.SubjectId ?addform.errors.SubjectId:null}
                                        >
                                        {SubjectList.map((subject,index) => (
                                            <MenuItem
                                            value={subject.SubjectId}
                                            key={`Subject_Subjectid${index}`}
                                            >
                                            <em>{subject.SubjectName}</em>
                                            </MenuItem>
                                        ))}
                                        
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                <FormControl fullWidth>
                                        <InputLabel id="lSubjectPointId">Subject Point</InputLabel>
                                        <Select
                                        labelId="lSubjectPointId"
                                        id="SubjectPointId"
                                        value={SubjectPointID}
                                        label="Subject Point"
                                        onChange={handleSubjectPointIDChange}
                                        onBlur={addform.handleBlur}
                                        error={addform.errors.SubjectPointId && addform.touched.SubjectPointId ?true:false}
                                        //helperText={addform.errors.SubjectId && addform.touched.SubjectId ?addform.errors.SubjectId:null}
                                        >
                                        {SubjectPointList.map((subject,index) => (
                                            <MenuItem
                                            value={subject.SubjectPointId}
                                            key={`SubjectPoint_Subjectpointid${index}`}
                                            >
                                            <em>{subject.SubjectPointName}</em>
                                            </MenuItem>
                                        ))}
                                        
                                        </Select>
                                    </FormControl>
                                </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                              <TextField
                                fullWidth
                                type="text"
                                autoComplete="off"
                                name="SubjectStepName"
                                id="SubjectStepName"
                                placeholder="Subject Point Step"
                                value={addform.values.SubjectStepName}
                                onChange={addform.handleChange}
                                onBlur={addform.handleBlur}
                                error={addform.errors.SubjectStepName && addform.touched.SubjectStepName ?true:false}
                                helperText={addform.errors.SubjectStepName && addform.touched.SubjectStepName ?addform.errors.SubjectStepName:null}
                              />
                              
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                              <TextField
                                fullWidth
                                type="text"
                                autoComplete="off"
                                name="SubjectStepDescription"
                                id="SubjectStepDescription"
                                placeholder="Subject Point Step Description "
                                value={addform.values.SubjectStepDescription}
                                onChange={addform.handleChange}
                                onBlur={addform.handleBlur}
                                error={addform.errors.SubjectStepDescription && addform.touched.SubjectStepDescription ?true:false}
                                helperText={addform.errors.SubjectStepDescription && addform.touched.SubjectStepDescription ?addform.errors.SubjectStepDescription:null}
                              />
                              
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                              <TextField
                                fullWidth
                                type="text"
                                autoComplete="off"
                                name="SubjectStepVideoUrl"
                                id="SubjectStepVideoUrl"
                                placeholder="Subject Point Step VideoUrl"
                                value={addform.values.SubjectStepVideoUrl}
                                onChange={addform.handleChange}
                                onBlur={addform.handleBlur}
                                error={addform.errors.SubjectStepVideoUrl && addform.touched.SubjectStepVideoUrl ?true:false}
                                helperText={addform.errors.SubjectStepVideoUrl && addform.touched.SubjectStepVideoUrl ?addform.errors.SubjectStepVideoUrl:null}
                              />
                              
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                              <TextField
                                fullWidth
                                type="number"
                                autoComplete="off"
                                name="OrderNo"
                                id="OrderNo"
                                placeholder="Order No"
                                value={addform.values.OrderNo}
                                onChange={addform.handleChange}
                                onBlur={addform.handleBlur}
                                error={addform.errors.OrderNo && addform.touched.OrderNo ?true:false}
                                helperText={addform.errors.OrderNo && addform.touched.OrderNo ?addform.errors.OrderNo:null}
                              />
                              
                      </Grid>
                      
                      
                      <Grid item xs={2} sm={4} md={4} style={{textAlign:'left',paddingLeft:'15px'}}>
                          
                          <FormControlLabel control={
                            <Checkbox
                            value={addform.values.IsActive}
                            checked={addform.values.IsActive}
                            onChange={handleIsActiveChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          } name='IsActive'
                          label="IsActive"
                          labelPlacement='end'>,

                          </FormControlLabel>
                          
                      </Grid>
                      
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                  </Grid>
                  <br></br>
                  <Button
                    type="submit"
                    variant="contained"
                    //sx={{ mt: 1, mb: 1 ,m:1}}
                    style={{width:"150px"}}
                  >
                    Save 
                  </Button>
                  </Box>
                  
                </TabPanel>
                
                <TabPanel value="2">
                           <p>Large Image</p>
                            <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={2} sm={4} md={6}>
                                    {selectedSubjectPointStepBaseImageLG && (
                                        <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            //src={URL.createObjectURL(selectedCategoryImage)}
                                            src={selectedSubjectPointStepBaseImageLG}
                                        />
                                        <br />
                                        <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedSubjectPointStepBaseImageLG(null)}>Remove</Button>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={4} md={6}>
                                    <input
                                        type="file"
                                        name="mySubjectPointStepImageLG"
                                        variant="contained"
                                        onChange={(event) => {
                                        
                                        getBase64(event.target.files[0]).then((result)=>{
                                            setselectedSubjectPointStepBaseImageLG(result);
                                        });
                                        setselectedSubjectPointStepImageLG(event.target.files[0]);
                                        console.log(event.target.files[0]);
                                        }}
                                    />
                                    
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 1}}
                                        style={{width:"150px"}}
                                        onClick={UploadSubjectPointStepImageLG}
                                    >
                                        Upload 
                                    </Button>
                                </Grid>
                            </Grid>
                            <p>Small Image</p>
                            <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={2} sm={4} md={6}>
                                    {selectedSubjectPointStepBaseImageSM && (
                                        <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            //src={URL.createObjectURL(selectedCategoryImage)}
                                            src={selectedSubjectPointStepBaseImageSM}
                                        />
                                        <br />
                                        <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedSubjectPointStepBaseImageSM(null)}>Remove</Button>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={4} md={6}>
                                    <input
                                        type="file"
                                        name="mySubjectPointStepImageSM"
                                        variant="contained"
                                        onChange={(event) => {
                                        
                                        getBase64(event.target.files[0]).then((result)=>{
                                            setselectedSubjectPointStepBaseImageSM(result);
                                        });
                                        setselectedSubjectPointStepImageSM(event.target.files[0]);
                                        console.log(event.target.files[0]);
                                        }}
                                    />
                                    
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 1}}
                                        style={{width:"150px"}}
                                        onClick={UploadSubjectPointStepImageSM}
                                    >
                                        Upload 
                                    </Button>
                                </Grid>
                            </Grid>
                </TabPanel>
              </TabContext>
            </Box>       
                       
            </div>
           <div>
           </div>
        </Container>
      </ThemeProvider>
    );
}