import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Field, Form, Formik, useFormik } from 'formik';
import { Container, Box, Typography, TextField, Button, CssBaseline, FormControl, InputLabel, Select, MenuItem, Grid, FormControlLabel, Checkbox, Tab, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@mui/material';
import {addcategory_validationSchema} from '../validations/addcategory_validationSchema'
import {addcategoryvideo_validationSchema} from '../validations/addcagegoryvideo_validationSchema'
import { useEffect, useState } from 'react';
import { ArrowBack, Block, CheckBox } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import axiosInstance from '../../axiosInstance';
import uploadfile_service from '../../services/uploadfile_service';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

export default function AddCategory (){
  const defaultTheme = createTheme();
  let { id} = useParams();
  const navigate = useNavigate();
  const [ParentCategoryID, setParentCategoryID] = useState(0);
  const [IsActive, setIsActive] = useState(false);
  const [value, setValue] = useState('1');
  const [ParentCategoryList,setParentCategoryList]=useState([]);
  const [tabDisabled,settabDisabled] =useState(true);
  const [selectedCategoryBaseImage, setselectedCategoryBaseImage] = useState(null);
  const [selectedCategoryBaseImageSlide, setselectedCategoryBaseImageSlide] = useState(null);

  const [selectedCategoryImage, setselectedCategoryImage] = useState(null);
  const [selectedCategoryImageSlide, setselectedCategoryImageSlide] = useState(null);

  const [CategoryImageMapID,setCategoryImageMapID]=useState(0);
  const [CategoryImageID,setCategoryImageID]=useState(0);
  const [CategoryImageSlideMapID,setCategoryImageSlideMapID]=useState(0);
  const [CategoryImageSlideID,setCategoryImageSlideID]=useState(0);

  const [imageslideList,setimageslideList]= useState([]);
  const [CategoryVideoList,setCategoryVideoList]= useState([]);

  useEffect((event)=>{
    console.log('id:' + id)
     axiosInstance.get('Category/GetParentCategoryMasters').then((response)=>{
      console.log(response.data);
      setParentCategoryList(response.data);
     });
     if(id != undefined || id != null){
      settabDisabled(false);
      axiosInstance.get('Category/GetCategoryMaster/' + id).then((response)=>{
        console.log(response.data);
        values.CategoryName=response.data.CategoryName;
        values.ParentCategoryID =response.data.ParentCategoryId ==null ? 0:response.data.ParentCategoryId;
        values.ShortDescription =response.data.ShortDescription;
        values.FullDescription =response.data.FullDescription;
        values.SeoTitle =response.data.SeoTitle;
        values.SeoKeyword =response.data.SeoKeyword;
        values.SeoDescription =response.data.SeoDescription;
        values.SeoUrl =response.data.SeoUrl;
        values.IsActive =response.data.IsActive;
        setIsActive(response.data.IsActive);
        setParentCategoryID(response.data.ParentCategoryId==null ? 0:response.data.ParentCategoryId)
       });
       axiosInstance.get('Category/GetCategoryImageMapByCategoryID/?CategoryID=' + id).then((response)=>{
        console.log("GetCategoryImageMapByCategoryID");
        //console.log(response.data);
        //console.log(response.data.Data);
        console.log(response.data.Data[0]);
        //console.log(response.data.Data[0]["ImageName"]);
        if(response.data.Data[0] != null || response.data.Data[0] != undefined){
          setCategoryImageMapID(response.data.Data[0]["CategoryImageMapID"]);
          setCategoryImageID(response.data.Data[0]["ImageID"])
          displayCategoryImage(response.data.Data[0]["ImageName"]);
        }
        
       });
       getSlideList();
       getVideoList();
     }
    
  },[])
  const routeChange = () =>{ 
    navigate('/category');
  }
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
    const initialValues = {
        CategoryName: "",
        ShortDescription: "",
        ParentCategoryID:0,
        FullDescription:"",
        SeoTitle:"", 
        SeoKeyword:"",
        SeoDescription:"",
        SeoUrl:"",
        IsActive:false
        
      };
      const initialVideoValues = {
        CategoryVideoMapId: 0,
        CategoryId:0,
        VideoId:0,
        VideoUrl:""
      };
      const handleParentIDChange = (event) => {
        console.log(event.target.value);
        setParentCategoryID(event.target.value);
        values.ParentCategoryID=event.target.value;
      };
      const handleIsActiveChange = (event) => {
        //console.log(event.target.value);
        setIsActive(IsActive?false:true)
        values.IsActive=IsActive?false:true;
        //console.log(IsActive)
        //console.log(IsActive)
        //setIsActive(event.target.value)
        //setBusinessTypeId(event.target.value);
      };
      
      const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
      useFormik({
        initialValues,
        validationSchema: addcategory_validationSchema,
        validateOnChange: true,
        validateOnBlur: false,
        //// By disabling validation onChange and onBlur formik will validate on submit.
        onSubmit: (values, action) => {
          console.log("action:",action.name);
          console.log("values:", values);
          //// to get rid of all the values after submitting the form
          //action.resetForm();
          console.log(values.CategoryName)
          console.log(values.ShortDescription)
          if(id===undefined || id ===null)
          {
            console.log("adding data")
            axiosInstance.post('Category/AddCategoryMaster',{
              //"CategoryId": 0,
              "ParentCategoryId": values.ParentCategoryID,
              "CategoryName": values.CategoryName,
              "ShortDescription": values.ShortDescription,
              "FullDescription": values.FullDescription,
              "SeoTitle": values.SeoTitle,
              "SeoKeyword": values.SeoKeyword,
              "SeoDescription": values.SeoDescription,
              "SeoUrl": values.SeoUrl,
              "IsActive": values.IsActive
            },
            ).then((response)=>{
                  console.log(response.data);
                  alert("Record save successfully")
                  navigate('/category');
            });

          }
          else{
            
            axiosInstance.post('Category/UpdateCompanyMaster',{
                "CategoryId": parseInt(id),
                "ParentCategoryId": values.ParentCategoryID,
                "CategoryName": values.CategoryName,
                "ShortDescription": values.ShortDescription,
                "FullDescription": values.FullDescription,
                "SeoTitle": values.SeoTitle,
                "SeoKeyword": values.SeoKeyword,
                "SeoDescription": values.SeoDescription,
                "SeoUrl": values.SeoUrl,
                "IsActive": values.IsActive
            },
            ).then((response)=>{
                console.log(response.data);
                alert("Updated successfully")
                navigate('/category');
            });
            
          }
        },
        
      });
      const UploadCategoryImage = event => {
        uploadfile_service.UploadCategoryImage(selectedCategoryImage,CategoryImageMapID,id,CategoryImageID).then((data)=>{
          //alert(data.data.Message)
          alert("Record saved successfull");
          console.log(data);
        }).catch((error)=>{
          alert("Failed");
          console.log(error);
        })
    };
    const UploadCategoryImageSlide = event => {
      uploadfile_service.UploadCategoryImageSlide(selectedCategoryImageSlide,CategoryImageSlideMapID,id,CategoryImageSlideID).then((data)=>{
        //alert(data.data.Message)
        getSlideList();
        alert("Record saved successfull");
        console.log(data);
      }).catch((error)=>{
        alert("Failed");
        console.log(error);
      })
  };
    async function displayCategoryImage(
      filename
    ) {
      // Fetch the image.
      var date = new Date();
      axiosInstance.get('File/GetImagebase64/?ndate='+ date.toDateString()+'&filename='+ filename).then( (response)=>{
        console.log(response);
        //const imageElement = document.getElementById(imageId);
        //imageElement.src = response.data;
        setselectedCategoryBaseImage(response.data);
       });
    }
    async function displayImageByNameImgID(
      filename,imageId
    ) {
      // Fetch the image.
      var date = new Date();
      axiosInstance.get('File/GetImagebase64/?ndate='+ date.toDateString()+'&filename='+ filename).then( (response)=>{
        console.log(response);
        try {
          const imageElement = document.getElementById(imageId);
          imageElement.src = response.data;
          imageElement.style.display="Block";
        }
        catch{

        }
        
       });
    }
    const getSlideList=()=>{
      axiosInstance.get('Category/GetCategoryImageSlideMapByCategoryID/?CategoryID=' + id).then((response)=>{
        console.log("GetCategoryImageSlideMapByCategoryID");
        //console.log(response.data);
        //console.log(response.data.Data);
        console.log(response.data.Data[0]);
        //console.log(response.data.Data[0]["ImageName"]);
        if(response.data.Data[0] != null || response.data.Data[0] != undefined){
          setimageslideList(response.data.Data[0])
          //setCategoryImageSlideMapID(response.data.Data[0]["CategoryImageSlideMapID"]);
          //setCategoryImageSlideID(response.data.Data[0]["ImageID"])
        }
       });
    }
    const getVideoList=()=>{
      axiosInstance.get('Category/GetCategoryVideoMapByCategoryID/?CategoryID=' + id).then((response)=>{
        console.log("GetCategoryVideoMapByCategoryID");
        //console.log(response.data);
        //console.log(response.data.Data);
        console.log(response.data.Data[0]);
        //console.log(response.data.Data[0]["ImageName"]);
        if(response.data.Data[0] != null || response.data.Data[0] != undefined){
          setCategoryVideoList(response.data.Data[0])
          //setCategoryImageSlideMapID(response.data.Data[0]["CategoryImageSlideMapID"]);
          //setCategoryImageSlideID(response.data.Data[0]["ImageID"])
        }
       });
    }
   function getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
      })
    }
    const videoform = useFormik({
      initialValues: {
          VideoUrl: ''
      },
      validationSchema: Yup.object({
          VideoUrl: Yup.string().required('Video url is required')
      }),
      onSubmit: (values) => {
          console.log(values)
          axiosInstance.post('Category/AddCategoryVideo',{
            "CategoryId": parseInt(id),
            "CategoryVideoMapId": 0,
            "VideoId": 0,
            "VideoUrl": values.VideoUrl
        },
        ).then((response)=>{
            getVideoList();
            console.log(response.data);
            alert("Added Video url successfully")
            
        });
      }
  });
  
      return (
        <ThemeProvider theme={defaultTheme}>
          <Container component="main">
            <CssBaseline />
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                 <Grid item xs={6}>
                    <Button 
                      style={{width:"150px"}}
                      startIcon={<ArrowBack />} onClick={routeChange}>
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                      <Typography component="h6" variant="h6">
                        Add category
                      </Typography>
                  </Grid>
            </Grid>
            
            
              <div style={{textAlign:'left'}}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                      <Tab label="Category detail" value="1" />
                      <Tab label="Image" value="2" disabled={tabDisabled}/>
                      <Tab label="Slides" value="3" disabled={tabDisabled}/>
                      <Tab label="Videos" value="4" disabled={tabDisabled}/>
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                  <Box
                      component="form"
                      // sx={{
                      //   '& .MuiTextField-root': { m: 1, width: '35ch' },
                      // }}
                      onSubmit={handleSubmit}
                    >
                    <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={4}>
                        <TextField
                                  fullWidth
                                  type="text"
                                  autoComplete="off"
                                  name="CategoryName"
                                  id="CategoryName"
                                  placeholder="Category Name"
                                  value={values.CategoryName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.CategoryName && touched.CategoryName ?true:false}
                                  helperText={errors.CategoryName && touched.CategoryName ?errors.CategoryName:null}
                                />
                                
                        </Grid>
                        <Grid  item xs={2} sm={4} md={4}>
                        <TextField
                                  fullWidth
                                  type="text"
                                  autoComplete="off"
                                  name="ShortDescription"
                                  id="ShortDescription"
                                  placeholder="Short Description"
                                  value={values.ShortDescription}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.ShortDescription && touched.ShortDescription ?true:false}
                                  helperText={errors.ShortDescription && touched.ShortDescription ?errors.ShortDescription:null}
                                />
                        </Grid>
                        <Grid  item xs={2} sm={4} md={4}>
                        <TextField
                                  fullWidth
                                  type="text"
                                  autoComplete="off"
                                  name="FullDescription"
                                  id="FullDescription"
                                  placeholder="Full Description"
                                  value={values.FullDescription}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  
                                />
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <FormControl fullWidth>
                                <InputLabel id="lParentCategoryID">Parent Category</InputLabel>
                                <Select
                                  labelId="lParentCategoryID"
                                  id="ParentCategoryID"
                                  value={ParentCategoryID}
                                  label="Parent Category"
                                  onChange={handleParentIDChange}
                                  onBlur={handleBlur}
                                >
                                  {/* <MenuItem value="1">
                                    <em>None</em>
                                  </MenuItem> */}
                                  {ParentCategoryList.map((category,index1) => (
                                      <MenuItem
                                      value={category.CategoryId}
                                      key={`category_CategoryId${index1}`}
                                      >
                                      <em>{category.CategoryName}</em>
                                      </MenuItem>
                                  ))}
                                  
                                </Select>
                              </FormControl>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <TextField
                                  fullWidth
                                  type="text"
                                  autoComplete="off"
                                  name="SeoTitle"
                                  id="SeoTitle"
                                  placeholder="Seo Title"
                                  value={values.SeoTitle}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                              <TextField
                                fullWidth
                                  type="text"
                                  autoComplete="off"
                                  name="SeoKeyword"
                                  id="SeoKeyword"
                                  placeholder="Seo Keyword"
                                  value={values.SeoKeyword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                              <TextField
                                fullWidth
                                  type="text"
                                  autoComplete="off"
                                  name="SeoDescription"
                                  id="SeoDescription"
                                  placeholder="Seo Description"
                                  value={values.SeoDescription}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                              <TextField
                                fullWidth
                                  type="text"
                                  autoComplete="off"
                                  name="SeoUrl"
                                  id="SeoUrl"
                                  placeholder="Seo Url"
                                  value={values.SeoUrl}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                        </Grid>
                        <Grid item xs={2} sm={4} md={4} style={{textAlign:'left',paddingLeft:'15px'}}>
                            
                            <FormControlLabel control={
                              <Checkbox
                              value={IsActive}
                              checked={IsActive}
                              onChange={handleIsActiveChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                            } name='IsActive'
                            label="IsActive"
                            labelPlacement='end'>,

                            </FormControlLabel>
                            
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          
                        </Grid>
                    </Grid>
                    <br></br>
                    <Button
                      type="submit"
                      variant="contained"
                      //sx={{ mt: 1, mb: 1 ,m:1}}
                      style={{width:"150px"}}
                    >
                      Save 
                    </Button>
                    </Box>
                    
                  </TabPanel>
                  <TabPanel value="2">
                     <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                         <Grid item xs={2} sm={4} md={6}>
                            {selectedCategoryBaseImage && (
                                <div>
                                  <img
                                    alt="not found"
                                    width={"250px"}
                                    //src={URL.createObjectURL(selectedCategoryImage)}
                                    src={selectedCategoryBaseImage}
                                  />
                                  <br />
                                  <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => selectedCategoryBaseImage(null)}>Remove</Button>
                                </div>
                              )}
                          </Grid>
                          <Grid item xs={2} sm={4} md={6}>
                              <input
                                type="file"
                                name="myCategoryImage"
                                variant="contained"
                                onChange={(event) => {
                                  console.log(event.target.files[0]);
                                  getBase64(event.target.files[0]).then((result)=>{
                                    setselectedCategoryBaseImage(result);
                                  });
                                  setselectedCategoryImage(event.target.files[0]);
                                }}
                              />
                              
                              <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 1}}
                                style={{width:"150px"}}
                                onClick={UploadCategoryImage}
                              >
                                Upload 
                              </Button>
                          </Grid>
                      </Grid>
                    </TabPanel>
                  <TabPanel value="3">
                     <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                         <Grid item xs={2} sm={4} md={6}>
                            {selectedCategoryImageSlide && (
                                <div>
                                  <img
                                    alt="not found"
                                    width={"250px"}
                                    src={URL.createObjectURL(selectedCategoryImageSlide)}
                                  />
                                  <br />
                                </div>
                              )}
                          </Grid>
                          <Grid item xs={2} sm={4} md={6}>
                              <input
                                type="file"
                                name="myCategoryImageSlide"
                                variant="contained"
                                onChange={(event) => {
                                  console.log(event.target.files[0]);
                                  getBase64(event.target.files[0]).then((result)=>{
                                    setselectedCategoryBaseImageSlide(result);
                                  });
                                  setselectedCategoryImageSlide(event.target.files[0]);
                                }}
                              />
                              <br></br>
                              <Button
                                type="submit"
                                variant="contained"
                                sx={{ mt: 1}}
                                style={{width:"150px"}}
                                onClick={UploadCategoryImageSlide}
                              >
                                Upload 
                              </Button>
                          </Grid>
                          <Grid item xs={4} sm={8} md={12}>
                              <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        SNo
                                      </TableCell>
                                      <TableCell>
                                        Image
                                      </TableCell>
                                     
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {imageslideList.map((item,index)=>(
                                      <TableRow key={item.CategoryImgSlideMapID}>
                                          <TableCell>
                                            {index+1}
                                          </TableCell>
                                          <TableCell>
                                            <img width={"100px"} height={"100px"} id={`imgslide${item.CategoryImgSlideMapID}`} style={{display:"none"}} ></img>
                                            {/* <Button onClick={displayImageByNameImgID(item.ImageName,`imgslide${item.CategoryImgSlideMapID}`)}></Button> */}
                                            <br></br>
                                            <Button type="submit"
                                              sx={{ mt: 1}}
                                              variant="contained"
                                              onClick={()=>{
                                                      displayImageByNameImgID(item.ImageName,`imgslide${item.CategoryImgSlideMapID}`)
                                               } }>Show Image</Button>
                                           <br></br>
                                            <Button type="submit"
                                                  sx={{ mt: 1}}
                                                  variant="contained">Remove</Button>
                                          </TableCell>
                                          
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                          </Grid>
                      </Grid>
                  </TabPanel>
                  <TabPanel value="4">
                    <form onSubmit={videoform.handleSubmit}>
                        
                        <TextField
                                fullWidth
                                  type="text"
                                  autoComplete="off"
                                  name="VideoUrl"
                                  id="VideoUrl"
                                  placeholder="Video Url"
                                  value={videoform.values.VideoUrl}
                                  onChange={videoform.handleChange}
                                  onBlur={videoform.handleBlur}
                                  error={videoform.errors.VideoUrl && videoform.touched.VideoUrl ?true:false}
                                  helperText={videoform.errors.VideoUrl && videoform.touched.VideoUrl ?videoform.errors.VideoUrl:null}
                                />
                                
                        <Button
                                      type="submit"
                                      variant="contained"
                                      sx={{ mt: 1}}
                                      style={{width:"150px"}}
                                    >
                                      Add Video 
                                    </Button>
                      </form>
                  
                      <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                         <Grid item xs={2} sm={4} md={6}>
                         
                          </Grid>
                          <Grid item xs={2} sm={4} md={6}>
                                  
                              
                          </Grid>

                        </Grid>
                      
                      <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                         
                          <Grid item xs={4} sm={8} md={12}>
                              <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        SNo
                                      </TableCell>
                                      <TableCell>
                                        Video
                                      </TableCell>
                                     
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {CategoryVideoList.map((item,index)=>(
                                      <TableRow key={`catvideorow${item.CategoryVideoMapID}`}>
                                          <TableCell>
                                            {index+1}
                                          </TableCell>
                                          <TableCell>
                                            <img width={"100px"} height={"100px"} id={`imgslide${item.CategoryVideoMapID}`} style={{display:"none"}} ></img>
                                            {/* <Button onClick={displayImageByNameImgID(item.ImageName,`imgslide${item.CategoryImgSlideMapID}`)}></Button> */}
                                            <br></br>
                                            <Link href={item.VideoUrl} target='_blank'>View Video</Link>
                                           <br></br>
                                            <Button type="submit"
                                                  sx={{ mt: 1}}
                                                  variant="contained">Remove</Button>
                                          </TableCell>
                                          
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                          </Grid>
                      </Grid>
                  </TabPanel>
                </TabContext>
              </Box>       
                         
              </div>
             <div>
             </div>
          </Container>
        </ThemeProvider>
      );
}