import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Field, Form, Formik, useFormik } from 'formik';
import { Container, Box, Typography, TextField, Button, CssBaseline, FormControl, InputLabel, Select, MenuItem, Grid, FormControlLabel, Checkbox, Tab, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { ArrowBack, Block, CheckBox } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import axiosInstance from '../../axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import uploadfile_service from '../../services/uploadfile_service';
export default function AddSubject(){
  const defaultTheme = createTheme();
  let { id} = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState('1');
  const [tabDisabled,settabDisabled] =useState(true);
  const [IsActive, setIsActive] = useState(false);
  const [Status, setStatus] = useState(false);
  const [Deleted, setDeleted] = useState(false);
  const [selectedSubjectBaseImageSM, setselectedSubjectBaseImageSM] = useState(null);
  const [selectedSubjectImageSM, setselectedSubjectImageSM] = useState(null);
  const [selectedSubjectBaseImageLG, setselectedSubjectBaseImageLG] = useState(null);
  const [selectedSubjectImageLG, setselectedSubjectImageLG] = useState(null);
  const [ImageIDSM,setImageIDSM]=useState(0);
  const [ImageIDLG,setImageIDLG]=useState(0);
  const [CourseList,setCourseList]=useState([]);
  const [CourseID, setCourseID] = useState(0);
  async function displayImageByNameLG(
    filename
  ) {
    var date = new Date();
    axiosInstance.get('File/GetImagebase64/?ndate='+ date.toDateString()+'&filename='+ filename).then( (response)=>{
      setselectedSubjectBaseImageLG(response.data);
     });
  };
  async function displayImageByNameSM(
    filename
  ) {
    var date = new Date();
    axiosInstance.get('File/GetImagebase64/?ndate='+ date.toDateString()+'&filename='+ filename).then( (response)=>{
      setselectedSubjectBaseImageSM(response.data);
     });
  };
  useEffect((event)=>{
    axiosInstance.get('Course/GetAllCourseList').then((response)=>{
        console.log(response.data);
        setCourseList(response.data);
       });
    console.log("id");
    console.log(id);
    if(id != undefined || id != null){
        settabDisabled(false);
        axiosInstance.get('Subject/GetSubjectMaster/' + id).then((response)=>{
            //console.log(response.data);
            //console.log(response.data.Data[0]);
            var ndata=response.data.Data[0];
            console.log('ndata');
            console.log(ndata);
            addform.values.SubjectName=ndata.SubjectName;
            addform.values.IsActive=ndata.IsActive;
            addform.values.ImageIdlg=ndata.ImageIDLG;
            addform.values.ImageIdsm=ndata.ImageIDSM;
            addform.values.CourseId=ndata.CourseID;
            setIsActive(ndata.IsActive);
            setImageIDLG(ndata.ImageIDLG);
            setImageIDSM(ndata.ImageIDSM);
            setCourseID(ndata.CourseID);
            if(ndata.ImageNameLG!=null){
                displayImageByNameLG(ndata.ImageNameLG);
            }
            if(ndata.ImageNameSM!=null){
                displayImageByNameSM(ndata.ImageNameSM);
            }
           });
    }
  },[]);

  const addform = useFormik({
    initialValues: {
        SubjectName: '',
        ImageIdsm:0,
        ImageIdlg:0,
        IsActive:true
    },
    validationSchema: Yup.object({
        SubjectName: Yup.string().required('Name is required'),
    }),
    onSubmit: (values) => {
        console.log(values)
        if(id===undefined || id ===null){
            axiosInstance.post('Subject/AddSubjectMaster',{
                "SubjectId": 0,
                "SubjectName": values.SubjectName,
                "CourseId": values.CourseId,
                "ImageIdsm":values.ImageIdsm,
                "ImageIdlg":values.ImageIdlg,
                "IsActive":values.IsActive
            },
            ).then((response)=>{
                //getVideoList();
                console.log(response.data);
                alert("Added Subject successfully")
                
            });
        }
        else{
            axiosInstance.post('Subject/UpdateSubjectMaster',{
                "SubjectId": parseInt(id),
                "SubjectName": values.SubjectName,
                "CourseId": values.CourseId,
                "ImageIdsm":values.ImageIdsm,
                "ImageIdlg":values.ImageIdlg,
                "IsActive":values.IsActive
            },
            ).then((response)=>{
                //getVideoList();
                console.log(response.data);
                alert("Updated Subject successfully")
                
            });
        }
       
    }
  });
  const routeChange = () =>{ 
    navigate('/Subjectlist');
  }
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  }; 
  
  const handleIsActiveChange = (event) => {
    setIsActive(IsActive?false:true)
    addform.values.IsActive=IsActive?false:true;
  };
  const handleCourseIDChange = (event) => {
    console.log(event.target.value);
    setCourseID(event.target.value);
    addform.values.CourseId=event.target.value;
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
    })
  };
  
  const UploadSubjectImageLG = event => {
    console.log('id:' + id);
    console.log('imageidlg:' + ImageIDLG);
    uploadfile_service.UploadSubjectImageType(selectedSubjectImageLG,id,ImageIDLG,2).then((data)=>{
      //alert(data.data.Message)
      alert("Record saved successfull");
      console.log(data);
    }).catch((error)=>{
      alert("Failed");
      console.log(error);
    })
  };
  const UploadSubjectImageSM = event => {
    console.log('id:' + id);
    console.log('imageidsm:' + ImageIDSM);
    uploadfile_service.UploadSubjectImageType(selectedSubjectImageSM,id,ImageIDSM,1).then((data)=>{
      //alert(data.data.Message)
      alert("Record saved successfull");
      console.log(data);
    }).catch((error)=>{
      alert("Failed");
      console.log(error);
    })
  };
  
    return(
        <ThemeProvider theme={defaultTheme}>
        <Container component="main">
          <CssBaseline />
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               <Grid item xs={6}>
                  <Button 
                    style={{width:"150px"}}
                    startIcon={<ArrowBack />} onClick={routeChange}>
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6}>
                    <Typography component="h6" variant="h6">
                      Add Subject
                    </Typography>
                </Grid>
          </Grid>
          
          
            <div style={{textAlign:'left'}}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                    <Tab label="Subject detail" value="1" />
                    <Tab label="Image" value="2" disabled={tabDisabled}/>
                  </TabList>
                </Box>
                <TabPanel value="1">
                <Box
                    component="form"
                    // sx={{
                    //   '& .MuiTextField-root': { m: 1, width: '35ch' },
                    // }}
                    onSubmit={addform.handleSubmit}
                  >
                  <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={6} sm={6} md={6}>
                                <FormControl fullWidth>
                                        <InputLabel id="lCourseId">Course</InputLabel>
                                        <Select
                                        labelId="lCourseId"
                                        id="CourseId"
                                        value={CourseID}
                                        label="Course"
                                        onChange={handleCourseIDChange}
                                        onBlur={addform.handleBlur}
                                        >
                                        {CourseList.map((course,index) => (
                                            <MenuItem
                                            value={course.CourseId}
                                            key={`course_courseid${index}`}
                                            >
                                            <em>{course.CourseName}</em>
                                            </MenuItem>
                                        ))}
                                        
                                        </Select>
                                    </FormControl>
                                </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                      <TextField
                                fullWidth
                                type="text"
                                autoComplete="off"
                                name="SubjectName"
                                id="SubjectName"
                                placeholder="Subject Name"
                                value={addform.values.SubjectName}
                                onChange={addform.handleChange}
                                onBlur={addform.handleBlur}
                                error={addform.errors.SubjectName && addform.touched.SubjectName ?true:false}
                                helperText={addform.errors.SubjectName && addform.touched.SubjectName ?addform.errors.SubjectName:null}
                              />
                              
                      </Grid>
                      
                      
                      <Grid item xs={2} sm={4} md={4} style={{textAlign:'left',paddingLeft:'15px'}}>
                          
                          <FormControlLabel control={
                            <Checkbox
                            value={addform.values.IsActive}
                            checked={addform.values.IsActive}
                            onChange={handleIsActiveChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          } name='IsActive'
                          label="IsActive"
                          labelPlacement='end'>,

                          </FormControlLabel>
                          
                      </Grid>
                      
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                      <Grid item xs={2} sm={4} md={4}>
                        
                      </Grid>
                  </Grid>
                  <br></br>
                  <Button
                    type="submit"
                    variant="contained"
                    //sx={{ mt: 1, mb: 1 ,m:1}}
                    style={{width:"150px"}}
                  >
                    Save 
                  </Button>
                  </Box>
                  
                </TabPanel>
                
                <TabPanel value="2">
                           <p>Large Image</p>
                            <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={2} sm={4} md={6}>
                                    {selectedSubjectBaseImageLG && (
                                        <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            //src={URL.createObjectURL(selectedCategoryImage)}
                                            src={selectedSubjectBaseImageLG}
                                        />
                                        <br />
                                        <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedSubjectBaseImageLG(null)}>Remove</Button>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={4} md={6}>
                                    <input
                                        type="file"
                                        name="mySubjectImageLG"
                                        variant="contained"
                                        onChange={(event) => {
                                        
                                        getBase64(event.target.files[0]).then((result)=>{
                                            setselectedSubjectBaseImageLG(result);
                                        });
                                        setselectedSubjectImageLG(event.target.files[0]);
                                        console.log(event.target.files[0]);
                                        }}
                                    />
                                    
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 1}}
                                        style={{width:"150px"}}
                                        onClick={UploadSubjectImageLG}
                                    >
                                        Upload 
                                    </Button>
                                </Grid>
                            </Grid>
                            <p>Small Image</p>
                            <Grid  container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                <Grid item xs={2} sm={4} md={6}>
                                    {selectedSubjectBaseImageSM && (
                                        <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            //src={URL.createObjectURL(selectedCategoryImage)}
                                            src={selectedSubjectBaseImageSM}
                                        />
                                        <br />
                                        <Button variant="contained" sx={{ mt: 1, mb: 1 ,m:1}} onClick={() => setselectedSubjectBaseImageSM(null)}>Remove</Button>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={2} sm={4} md={6}>
                                    <input
                                        type="file"
                                        name="mySubjectImageSM"
                                        variant="contained"
                                        onChange={(event) => {
                                        
                                        getBase64(event.target.files[0]).then((result)=>{
                                            setselectedSubjectBaseImageSM(result);
                                        });
                                        setselectedSubjectImageSM(event.target.files[0]);
                                        console.log(event.target.files[0]);
                                        }}
                                    />
                                    
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 1}}
                                        style={{width:"150px"}}
                                        onClick={UploadSubjectImageSM}
                                    >
                                        Upload 
                                    </Button>
                                </Grid>
                            </Grid>
                </TabPanel>
              </TabContext>
            </Box>       
                       
            </div>
           <div>
           </div>
        </Container>
      </ThemeProvider>
    );
}