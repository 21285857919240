import './App.css';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';

import AdminLayout from './layouts/AdminLayout';
import AdminDashboard from './adminpages/index'
import ErrorPage from './pages/ErrorPage';
import Privacy from './pages/Privacy';
import ProPrivacy from './pages/ProPrivacy';
import SignIn from './adminpages/SignIn';
import CategoryList from './adminpages/categorymaster/CategoryList';
import AddCategory from './adminpages/categorymaster/AddCategory';
import SlideList from './adminpages/slidemaster/slidelist';
import AddSlide from './adminpages/slidemaster/addslide';
import CourseList from './adminpages/coursemaster/courselist';
import AddCourse from './adminpages/coursemaster/addcourse';
import SubjectList from './adminpages/subjectmaster/subjectlist';
import AddSubject from './adminpages/subjectmaster/addsubject';
import QuestionList from './adminpages/questionmaster/questionlist';
import AddQuestion from './adminpages/questionmaster/addquestion';
import SubjectPointList from './adminpages/subjectpointmaster/subjectpointlist';
import AddSubjectPoint from './adminpages/subjectpointmaster/addsubjectpoint';
import SubjectPointStepList from './adminpages/subjectpointstepmaster/subjectpointsteplist';
import AddSubjectPointStep from './adminpages/subjectpointstepmaster/addsubjectpointstep';
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
         <Route path="/" element={ <AdminLayout/>}>
            <Route index element={<AdminDashboard/>}/>
            <Route path='category' element={<CategoryList/>}/>
            <Route path='addcategory' element={<AddCategory/>}/>
            <Route path='addcategory/:id' element={<AddCategory/>}/>
            <Route path='slidelist' element={<SlideList/>}/>
            <Route path='addslide' element={<AddSlide/>}/>
            <Route path='addslide/:id' element={<AddSlide/>}/>
            <Route path='courselist' element={<CourseList/>}/>
            <Route path='addcourse' element={<AddCourse/>}/>
            <Route path='addcourse/:id' element={<AddCourse/>}/>
            <Route path='subjectlist' element={<SubjectList/>}/>
            <Route path='addsubject' element={<AddSubject/>}/>
            <Route path='addsubject/:id' element={<AddSubject/>}/>
            <Route path='questionlist' element={<QuestionList/>}/>
            <Route path='addquestion' element={<AddQuestion/>}/>
            <Route path='addquestion/:id' element={<AddQuestion/>}/>
            <Route path='subjectpointlist' element={<SubjectPointList/>}/>
            <Route path='addsubjectpoint' element={<AddSubjectPoint/>}/>
            <Route path='addsubjectpoint/:id' element={<AddSubjectPoint/>}/>
            <Route path='subjectpointsteplist' element={<SubjectPointStepList/>}/>
            <Route path='addsubjectpointstep' element={<AddSubjectPointStep/>}/>
            <Route path='addsubjectpointstep/:id' element={<AddSubjectPointStep/>}/>
          </Route>
          <Route path='/login' element={<SignIn/>}/>
          <Route path='/proprivacy' element={<ProPrivacy/>}/>
          <Route path='/privacy' element={<Privacy/>}/>
          {/* <Route path="/" element={ <WebLayout/>}>
            <Route index element={<Index/>}/>
            <Route path='about' element={<About/>}/>
            <Route path='privacy' element={<Privacy/>}/>
          </Route>
          <Route path="/admin" element={ <AdminLayout/>}>
            <Route index element={<AdminDashboard/>}/>
            <Route path='company' element={<CompanyList/>}/>
            <Route path='addcompany' element={<AddCompany/>}/>
            <Route path='addcompany/:id' element={<AddCompany/>}/>
            <Route path='userlist' element={<UserList/>}/>
            <Route path='adduser' element={<AddUser/>}/>
            <Route path='adduser/:id' element={<AddUser/>}/>
            <Route path='setting' element={<Setting/>}/>
          </Route>
          <Route path='/login' element={<SignIn/>}/> */}
          <Route path="*" element={ <ErrorPage/>}/>
        </Routes>        
      </Router>     
    </div>
  );
}

export default App;
